<template>
  <div>
    <b-card>
      <div slot="header">Commands</div>
      <b-tabs v-model="tabIndex" no-fade>
        <b-tab :key="200" title="Global Settings"></b-tab>
        <b-tab v-for="(commands, name) in command_data" :key="name" :title="commands.module">
          <b-form inline>
            <b-button
              class="mx-1 navtab_responsive"
              variant="success"
              @click="openmodalcategorically"
            >Manage category</b-button>
            <b-button
              class="mx-1 navtab_responsive"
              variant="danger"
              @click="openconfirm"
            >Reset category</b-button>
            <b-button
              class="mx-1 navtab_responsive"
              variant="primary"
              @click="selecting = !selecting"
              v-if="!selecting"
            >Select multiple</b-button>
            <b-button
              class="mx-1 navtab_responsive"
              variant="primary"
              @click="openmultiplemodal"
              v-else
              :disabled="selected_commands.length == 0 && selecting"
            >{{'Manage ' + selected_commands.length + ' selected'}}</b-button>
            <b-button
              class="mx-1 navtab_responsive"
              variant="ghost-danger"
              v-if="selecting"
              @click="selecting = false;selected_commands=[]"
            >Clear</b-button>
            <b-button
              class="mx-1 navtab_responsive"
              variant="outline-success"
              v-if="!selecting"
              @click="enable_all"
            >Enable category</b-button>
            <b-button
              class="mx-1 navtab_responsive"
              variant="outline-danger"
              v-if="!selecting"
              @click="disable_all"
            >Disable category</b-button>
            <b-button
              class="mx-1 navtab_responsive"
              variant="outline-info"
              v-if="selecting"
              @click="select_all"
            >Select category</b-button>
            <b-button
              class="mx-1 navtab_responsive"
              variant="outline-info"
              v-if="selecting"
              @click="unselect_all"
            >Unselect category</b-button>
            <b-form-group>
              <b-input-group>
                <b-form-input
                  id="searchbar"
                  type="text"
                  pattern="[A-z0-9-_]{1,50}"
                  placeholder="Filter commands"
                  class="ml-1"
                  v-model="command_filter"
                ></b-form-input>
                <div class="input-group-append">
                  <div class="input-group-text">
                    <i class="icon-magnifier"></i>
                  </div>
                </div>
                <b-form-checkbox v-model="match_desc" class="ml-2 mt-1">Match description</b-form-checkbox>
              </b-input-group>
            </b-form-group>
          </b-form>
        </b-tab>
        <b-col class="col-12">
          <b-row v-if="tabIndex != 0">
            <b-col
              sm="6"
              md="4"
              lg="3"
              v-for="(extra, command) in relevant_commands"
              :key="name + command"
            >
              <b-card :class="card_accent(extra)">
                <div>
                  <b-form-checkbox
                    v-if="selecting"
                    :value="command"
                    v-model="selected_commands"
                    style="mt-4"
                  ></b-form-checkbox>
                  <h4 class="smalltitle">
                    {{command}}
                    <a
                      v-if="extra.checks && !['kick', 'ban', 'massban', 'softban', 'unban', 'tempban','timeout','removetimeout'].includes(command)"
                      style="font-size:0.775rem;"
                      class="badge badge-primary ml-1 float-right"
                      :id="'manage_x_badge' + command"
                    >
                      Manage {{extra.checks}}
                      <b-popover
                        triggers="hover"
                        placement="top"
                        :target="'manage_x_badge' + command"
                      >This command requires this discord permission to work. Optionally you can give a member the 'bot manager role' to bypass this restriction.</b-popover>
                    </a>
                    <a
                      v-if="['kick', 'ban', 'massban', 'softban', 'unban', 'tempban','timeout','removetimeout'].includes(command)"
                      style="font-size:0.775rem;"
                      class="badge badge-primary ml-1 float-right"
                    >{{command === 'timeout'?'timeout':command === 'removetimeout'?'timeout':command==='kick' ? 'Kick' : 'Ban'}} Members</a>
                  </h4>
                </div>

                <hr />
                {{extra.description}}
                <hr />
                <b-row class="justify-content-center">
                  <b-col cols="auto" class="mr-auto px-3 pt-2">
                    <div
                      @click.prevent="extra.enabled = !extra.enabled;toggle(command, extra.enabled);"
                    >
                      <c-switch
                        color="success"
                        label
                        variant="pill"
                        size="sm"
                        v-bind="labelIcon"
                        v-model="extra.enabled"
                      />
                    </div>
                  </b-col>
                  <b-col cols="auto" class="px-2">
                    <div>
                      <b-button variant="ghost-primary" v-on:click="openmodal(extra)">
                        <font-awesome-icon :icon="['fa', 'cog']" />
                      </b-button>
                    </div>
                  </b-col>
                </b-row>
              </b-card>
            </b-col>
          </b-row>
          <b-row v-else>
            <b-col xs="12" xl="6">
              <b-card>
                <div slot="header">
                  <h4 class="smalltitle">Global Settings</h4>
                </div>
                <div v-if="loading">
                  <div class="sk-circle-fade float-left">
                    <div class="sk-grid-cube"></div>
                    <div class="sk-grid-cube"></div>
                    <div class="sk-grid-cube"></div>
                    <div class="sk-grid-cube"></div>
                    <div class="sk-grid-cube"></div>
                    <div class="sk-grid-cube"></div>
                    <div class="sk-grid-cube"></div>
                    <div class="sk-grid-cube"></div>
                    <div class="sk-grid-cube"></div>
                  </div>
                  <h2 class="float-left ml-4">Loading commands...</h2>
                </div>

                <div v-else>
                  <p>Global whitelisted channels and roles ignore any overwrites. Per-command whitelisted channels and roles means that messages sent outside of those channels/from people without any of the roles cannot invoke the command. Disabled commands really are disabled, even for mods.</p>
                  <b-form-group>
                    <hr />
                    <b-form-group label="Ignore overwrites in" label-for="global_channel_wl">
                      <multiselect
                        v-model="global_channel_wl"
                        :options="groupedChannels"
                        group-values="channels"
                        group-label="category"
                        :group-select="false"
                        :multiple="true"
                        label="name"
                        track-by="id"
                        id="global_channel_wl"
                      >
                        <template slot="tag" slot-scope="{option, search, remove}">
                          <span
                            class="multiselect__tag channelpicker__tag"
                            :style="{borderColor: '#eee'}"
                          >
                            <i
                              aria-hidden="true"
                              tabindex="1"
                              @keydown.enter.prevent="remove(option)"
                              @mousedown.prevent="remove(option)"
                              class="multiselect__tag-icon channelpicker_icon"
                              :style="{backgroundColor: '#eee'}"
                            ></i>
                            <font-awesome-icon style="color:#999;margin-right:3px;" icon="hashtag" />
                            <span class="defaultcursor" v-text="option.name"></span>
                          </span>
                        </template>
                        <template slot="option" slot-scope="props">
                          <div class="option__desc">
                            <span class="option__title">
                              <font-awesome-icon
                                style="color:#72767d"
                                :icon="!props.option['$isLabel'] ? 'hashtag' : 'chevron-down'"
                              />
                              {{ props.option.name || props.option.$groupLabel.name }}
                            </span>
                          </div>
                        </template>
                      </multiselect>
                    </b-form-group>
                    <b-form-group label="Blacklisted Channels" label-for="global_channel_bl">
                      <multiselect
                        v-model="global_channel_bl"
                        :options="groupedChannels"
                        :multiple="true"
                        group-values="channels"
                        group-label="category"
                        :group-select="false"
                        label="name"
                        track-by="id"
                        id="global_channel_bl"
                      >
                        <template slot="tag" slot-scope="{option, search, remove}">
                          <span
                            class="multiselect__tag channelpicker__tag"
                            :style="{borderColor: '#eee'}"
                          >
                            <i
                              aria-hidden="true"
                              tabindex="1"
                              @keydown.enter.prevent="remove(option)"
                              @mousedown.prevent="remove(option)"
                              class="multiselect__tag-icon channelpicker_icon"
                              :style="{backgroundColor: '#eee'}"
                            ></i>
                            <font-awesome-icon style="color:#999;margin-right:3px;" icon="hashtag" />
                            <span class="defaultcursor" v-text="option.name"></span>
                          </span>
                        </template>
                        <template slot="option" slot-scope="props">
                          <div class="option__desc">
                            <span class="option__title">
                              <font-awesome-icon
                                style="color:#72767d"
                                :icon="!props.option['$isLabel'] ? 'hashtag' : 'chevron-down'"
                              />
                              {{ props.option.name || props.option.$groupLabel.name }}
                            </span>
                          </div>
                        </template>
                      </multiselect>
                    </b-form-group>
                    <b-form-group label="Roles that ignore overwrites" label-for="global_role_wl">
                      <multiselect
                        v-model="global_role_wl"
                        :options="roles"
                        :multiple="true"
                        label="name"
                        track-by="id"
                        id="global_role_wl"
                      >
                        <template slot="tag" slot-scope="{option, search, remove}">
                          <span
                            class="multiselect__tag"
                            :style="{'border-color': option ? '#' + option.color.toString(16) : '#eeaaee'}"
                          >
                            <i
                              aria-hidden="true"
                              tabindex="1"
                              @keydown.enter.prevent="remove(option)"
                              @mousedown.prevent="remove(option)"
                              class="multiselect__tag-icon fakediscord"
                              :style="{'background-color': '#' + intToHex(option.color || 12172222)}"
                            ></i>
                            <span class="defaultcursor" v-text="option.name"></span>
                          </span>
                        </template>
                        <template slot="option" slot-scope="props">
                          <div :style="{'color': parseColor(props.option)}" class="option__desc">
                            <span class="option__title">{{ props.option.name }}</span>
                          </div>
                        </template>
                      </multiselect>
                    </b-form-group>
                    <b-form-group label="Blacklisted Roles" label-for="global_role_bl">
                      <multiselect
                        v-model="global_role_bl"
                        :options="roles"
                        :multiple="true"
                        label="name"
                        track-by="id"
                        id="global_role_bl"
                      >
                        <template slot="tag" slot-scope="{option, search, remove}">
                          <span
                            class="multiselect__tag"
                            :style="{'border-color': option ? '#' + option.color.toString(16) : '#eeaaee'}"
                          >
                            <i
                              aria-hidden="true"
                              tabindex="1"
                              @keydown.enter.prevent="remove(option)"
                              @mousedown.prevent="remove(option)"
                              class="multiselect__tag-icon fakediscord"
                              :style="{'background-color': '#' + intToHex(option.color)}"
                            ></i>
                            <span class="defaultcursor" v-text="option.name"></span>
                          </span>
                        </template>
                        <template slot="option" slot-scope="props">
                          <div :style="{'color': parseColor(props.option)}" class="option__desc">
                            <span class="option__title">{{ props.option.name }}</span>
                          </div>
                        </template>
                      </multiselect>
                    </b-form-group>
                    <b-form-group label="Bot Channels" label-for="global_bot_channels">
                      <multiselect
                        v-model="global_bot_channels"
                        :options="groupedChannels"
                        :multiple="true"
                        group-values="channels"
                        group-label="category"
                        :group-select="false"
                        label="name"
                        track-by="id"
                        id="global_bot_channels"
                      >
                        <template slot="tag" slot-scope="{option, search, remove}">
                          <span
                            class="multiselect__tag channelpicker__tag"
                            :style="{borderColor: '#eee'}"
                          >
                            <i
                              aria-hidden="true"
                              tabindex="1"
                              @keydown.enter.prevent="remove(option)"
                              @mousedown.prevent="remove(option)"
                              class="multiselect__tag-icon channelpicker_icon"
                              :style="{backgroundColor: '#eee'}"
                            ></i>
                            <font-awesome-icon style="color:#999;margin-right:3px;" icon="hashtag" />
                            <span class="defaultcursor" v-text="option.name"></span>
                          </span>
                        </template>
                        <template slot="option" slot-scope="props">
                          <div class="option__desc">
                            <span class="option__title">
                              <font-awesome-icon
                                style="color:#72767d"
                                :icon="!props.option['$isLabel'] ? 'hashtag' : 'chevron-down'"
                              />
                              {{ props.option.name || props.option.$groupLabel.name }}
                            </span>
                          </div>
                        </template>
                      </multiselect>
                    </b-form-group>
                    <b-form-group label="Bot Manager Role" label-for="mod_role_select">
                      <multiselect
                        v-model="global_modrole"
                        id="discord_channel_select"
                        track-by="id"
                        label="name"
                        placeholder="Please select a role"
                        open-direction="bottom"
                        :searchable="true"
                        :clear-on-select="false"
                        :close-on-select="true"
                        :max-height="600"
                        :show-no-results="false"
                        :hide-selected="false"
                        :options="botManagerRoles"
                        :allow-empty="false"
                      >
                        <template slot="singleLabel" slot-scope="props">
                          <span class="option__desc">
                            <font-awesome-icon style="color:#72767d" icon="user-tag" />
                            <span class="option__title ml-2">{{ props.option.name }}</span>
                          </span>
                        </template>
                        <template slot="option" slot-scope="props">
                          <div class="option__desc">
                            <span
                              class="option__title"
                              :style="{color: '#' + intToHex(props.option.color)}"
                            >{{ props.option.name }}</span>
                          </div>
                        </template>
                      </multiselect>
                    </b-form-group>

                    <b-row class="mr-auto">
                      <b-col cols="auto" class="mr-auto py-1">
                        <label for="modonly_toggle_switch">
                          <h6>Entire bot mod only</h6>
                        </label>
                      </b-col>
                      <b-col cols="auto" class="p-1">
                        <c-switch
                          id="modonly_toggle_switch"
                          color="info"
                          label
                          variant="pill"
                          v-bind="labelIcon"
                          v-model="global_modonly"
                        />
                      </b-col>
                    </b-row>
                    <b-row class="mr-auto">
                      <b-col cols="auto" class="mr-auto py-1">
                      <div @mouseup="elevated_perms_toggle()">
                        <label for="elevated_perms_toggle_switch">
                          <h6>Allow bot to assign roles with elevated permissions
                            <font-awesome-icon
                              style="color:#888"
                              :icon="['fas', 'question-circle']"
                              class="mx-2"
                              id="restricted_question"
                            />
                            <b-popover
                              triggers="hover"
                              placement="top"
                              target="restricted_question"
                            >  This toggle will allow the bot to assign roles with the following permissions: Manage Server,
                                            Manage Channels,
                                            Manage Roles,
                                            Manage Messages,
                                            Manage Emojis,
                                            Manage Nicknames,
                                            Manage Webhooks,
                                            Ban Members,
                                            Kick Members.</b-popover></h6>
                        </label>
                        </div>
                      </b-col>
                      <b-col cols="auto" class="p-1">
                      <div @mouseup="elevated_perms_toggle()">
                        <c-switch
                          id="elevated_perms_toggle_switch"
                          color="info"
                          label
                          variant="pill"
                          v-bind="labelIcon"
                          v-model="elevated_permissions"
                        />
                      </div>
                      </b-col>
                    </b-row>
                  </b-form-group>
                  <div slot="footer" class="w-100">
                    <b-container fluid class="text-light text-center" style="padding-right:0px;">
                      <b-row class="mr-auto">
                        <b-col cols="auto" class="mr-auto p-2"></b-col>
                        <b-col cols="auto" class="p-2">
                          <b-button variant="ghost-secondary" v-on:click="reset_globals">Reset</b-button>
                        </b-col>
                        <b-col cols="auto" class="p-2">
                          <b-button variant="success" @click="send_globals">Save</b-button>
                        </b-col>
                      </b-row>
                    </b-container>
                  </div>
                </div>
              </b-card>
            </b-col>
          </b-row>
        </b-col>
      </b-tabs>
    </b-card>
    <b-modal size="md" v-model="overwritemodal" :no-enforce-focus="true">
      <div slot="modal-title">
        <h4 class="subtitle">{{modaltitle}}</h4>
      </div>
      <b-form-group>
        <p v-if="!Array.isArray(current_command)">{{current_command.description}}</p>
        <strong v-else>This will overwrite ALL settings for these commands.</strong>
        <hr />

        <b-form-group label="Require one of the following channels" label-for="channel_wl">
          <multiselect
            v-model="channel_wl"
            :options="groupedChannels"
            :multiple="true"
            group-values="channels"
            group-label="category"
            :group-select="false"
            label="name"
            track-by="id"
            id="channel_wl"
          >
            <template slot="tag" slot-scope="{option, search, remove}">
              <span class="multiselect__tag channelpicker__tag" :style="{borderColor: '#eee'}">
                <i
                  aria-hidden="true"
                  tabindex="1"
                  @keydown.enter.prevent="remove(option)"
                  @mousedown.prevent="remove(option)"
                  class="multiselect__tag-icon channelpicker_icon"
                  :style="{backgroundColor: '#eee'}"
                ></i>
                <font-awesome-icon style="color:#999;margin-right:3px;" icon="hashtag" />
                <span class="defaultcursor" v-text="option.name"></span>
              </span>
            </template>
            <template slot="option" slot-scope="props">
              <div class="option__desc">
                <span class="option__title">
                  <font-awesome-icon
                    style="color:#72767d"
                    :icon="!props.option['$isLabel'] ? 'hashtag' : 'chevron-down'"
                  />
                  {{ props.option.name || props.option.$groupLabel.name }}
                </span>
              </div>
            </template>
          </multiselect>
        </b-form-group>
        <b-form-group label="Blacklisted Channels" label-for="channel_bl">
          <multiselect
            v-model="channel_bl"
            :options="groupedChannels"
            :multiple="true"
            group-values="channels"
            group-label="category"
            :group-select="false"
            label="name"
            track-by="id"
            id="channel_bl"
          >
            <template slot="tag" slot-scope="{option, search, remove}">
              <span class="multiselect__tag channelpicker__tag" :style="{borderColor: '#eee'}">
                <i
                  aria-hidden="true"
                  tabindex="1"
                  @keydown.enter.prevent="remove(option)"
                  @mousedown.prevent="remove(option)"
                  class="multiselect__tag-icon channelpicker_icon"
                  :style="{backgroundColor: '#eee'}"
                ></i>
                <font-awesome-icon style="color:#999;margin-right:3px;" icon="hashtag" />
                <span class="defaultcursor" v-text="option.name"></span>
              </span>
            </template>
            <template slot="option" slot-scope="props">
              <div class="option__desc">
                <span class="option__title">
                  <font-awesome-icon
                    style="color:#72767d"
                    :icon="!props.option['$isLabel'] ? 'hashtag' : 'chevron-down'"
                  />
                  {{ props.option.name || props.option.$groupLabel.name }}
                </span>
              </div>
            </template>
          </multiselect>
        </b-form-group>
        <b-form-group label="Require one of the following roles" label-for="role_wl">
          <multiselect
            v-model="role_wl"
            :options="roles"
            :multiple="true"
            label="name"
            track-by="id"
            id="role_wl"
          >
            <template slot="tag" slot-scope="{option, search, remove}">
              <span
                class="multiselect__tag"
                :style="{'border-color': option ? '#' + option.color.toString(16) : '#eeaaee'}"
              >
                <i
                  aria-hidden="true"
                  tabindex="1"
                  @keydown.enter.prevent="remove(option)"
                  @mousedown.prevent="remove(option)"
                  class="multiselect__tag-icon fakediscord"
                  :style="{'background-color': '#' + intToHex(option.color)}"
                ></i>
                <span class="defaultcursor" v-text="option.name"></span>
              </span>
            </template>
            <template slot="option" slot-scope="props">
              <div :style="{'color': parseColor(props.option)}" class="option__desc">
                <span class="option__title">{{ props.option.name }}</span>
              </div>
            </template>
          </multiselect>
        </b-form-group>
        <b-form-group label="Blacklisted Roles" label-for="role_bl">
          <multiselect
            v-model="role_bl"
            :options="roles"
            :multiple="true"
            label="name"
            track-by="id"
            id="role_bl"
          >
            <template slot="tag" slot-scope="{option, search, remove}">
              <span
                class="multiselect__tag"
                :style="{'border-color': option ? '#' + option.color.toString(16) : '#eeaaee'}"
              >
                <i
                  aria-hidden="true"
                  tabindex="1"
                  @keydown.enter.prevent="remove(option)"
                  @mousedown.prevent="remove(option)"
                  class="multiselect__tag-icon fakediscord"
                  :style="{'background-color': '#' + intToHex(option.color)}"
                ></i>
                <span class="defaultcursor" v-text="option.name"></span>
              </span>
            </template>
            <template slot="option" slot-scope="props">
              <div :style="{'color': parseColor(props.option)}" class="option__desc">
                <span class="option__title">{{ props.option.name }}</span>
              </div>
            </template>
            <template slot="option" slot-scope="props">
              <div :style="{'color': parseColor(props.option)}" class="option__desc">
                <span class="option__title">{{ props.option.name }}</span>
              </div>
            </template>
          </multiselect>
        </b-form-group>
        <b-form-group
          label="Bypass Discord permission requirements"
          label-for="overrides"
          v-show="command_checks"
        >
          <multiselect
            v-model="overrides"
            :options="roles"
            :multiple="true"
            label="name"
            track-by="id"
            id="overrides"
          >
            <template slot="tag" slot-scope="{option, search, remove}">
              <span
                class="multiselect__tag"
                :style="{'border-color': option ? '#' + option.color.toString(16) : '#eeaaee'}"
              >
                <i
                  aria-hidden="true"
                  tabindex="1"
                  @keydown.enter.prevent="remove(option)"
                  @mousedown.prevent="remove(option)"
                  class="multiselect__tag-icon fakediscord"
                  :style="{'background-color': '#' + intToHex(option.color)}"
                ></i>
                <span class="defaultcursor" v-text="option.name"></span>
              </span>
            </template>
            <template slot="option" slot-scope="props">
              <div :style="{'color': parseColor(props.option)}" class="option__desc">
                <span class="option__title">{{ props.option.name }}</span>
              </div>
            </template>
          </multiselect>
        </b-form-group>
        <b-form-group label="Redirect output" label-for="redirection_channel">
          <multiselect
            v-model="redirection_channel"
            id="discord_channel_select"
            track-by="id"
            label="name"
            placeholder="Please select a channel"
            open-direction="bottom"
            :searchable="true"
            :clear-on-select="false"
            :close-on-select="true"
            :max-height="600"
            :show-no-results="false"
            :hide-selected="false"
            :options="groupedRedirectOptions"
            :allow-empty="false"
            group-values="channels"
            group-label="category"
            :group-select="false"
          >
            <template slot="singleLabel" slot-scope="props">
              <span class="option__desc">
                <font-awesome-icon style="color:#72767d" icon="hashtag" />
                <span class="option__title ml-2">{{ props.option.name }}</span>
              </span>
            </template>
            <template slot="option" slot-scope="props">
              <div class="option__desc">
                <span class="option__title">
                  <font-awesome-icon
                    style="color:#72767d"
                    :icon="!props.option['$isLabel'] ? 'hashtag' : 'chevron-down'"
                  />
                  {{ props.option.name || props.option.$groupLabel.name }}
                </span>
              </div>
            </template>
          </multiselect>
        </b-form-group>

        <b-row class="mr-auto">
          <b-col cols="auto" class="mr-auto py-1">
            <label for="modonly_toggle_switch">
              <h5 class="tinytitle">Mod only</h5>
            </label>
          </b-col>
          <b-col cols="auto" class="p-1">
            <c-switch
              id="modonly_toggle_switch"
              color="info"
              label
              variant="pill"
              v-bind="labelIcon"
              v-model="modonly"
            />
          </b-col>
        </b-row>
        <b-row class="mr-auto">
          <b-col cols="auto" class="mr-auto py-1">
            <label for="restricted_toggle_switch">
              <h5 class="tinytitle">
                Restricted
                <font-awesome-icon
                  style="color:#888"
                  :icon="['fas', 'question-circle']"
                  class="mx-2"
                  id="restricted_question"
                />
                <b-popover triggers="hover" placement="top" target="restricted_question">
                  This
                  <b>requires</b> a bot channel to utilize.
                  <br />Makes it so that if the command is used outside of the bot channel, the bot will ping the user in the bot channel and respond in that channel instead.
                  <br />Useful if you want to let people use commands but not have them clutter your main channels.
                </b-popover>
              </h5>
            </label>
          </b-col>
          <b-col cols="auto" class="p-1">
            <c-switch
              id="restricted_toggle_switch"
              color="info"
              label
              variant="pill"
              v-bind="labelIcon"
              v-model="restricted"
            />
          </b-col>
        </b-row>
        <b-row class="mr-auto">
          <b-col cols="auto" class="mr-auto py-1">
            <label for="muted_toggle_switch">
              <h5 class="tinytitle">Mute output</h5>
            </label>
          </b-col>
          <b-col cols="auto" class="p-1">
            <c-switch
              id="muted_toggle_switch"
              color="info"
              label
              variant="pill"
              v-bind="labelIcon"
              v-model="muted"
            />
          </b-col>
        </b-row>
        <b-row class="mr-auto">
          <b-col cols="auto" class="mr-auto py-1">
            <label for="sneaky_toggle_switch">
              <h5 class="tinytitle">Delete invocation</h5>
            </label>
          </b-col>
          <b-col cols="auto" class="p-1">
            <c-switch
              id="sneaky_toggle_switch"
              color="info"
              label
              variant="pill"
              v-bind="labelIcon"
              v-model="sneaky"
            />
          </b-col>
        </b-row>
      </b-form-group>
      <h4
        v-if="current_command.subcommands && current_command.subcommands.length != 0"
        class="tinytitle"
      >Subcommands</h4>
      <div v-for="sub in current_command.subcommands" :key="sub.command">
        <label :for="'checkbox' + sub.command" style="margin:0">
          <b-form-checkbox
            :id="'checkbox' + sub.command"
            :enabled="sub.enabled"
            v-model="sub.enabled"
          >{{sub.command}}</b-form-checkbox>
        </label>
      </div>
      <div slot="modal-footer" class="w-100">
        <b-container fluid class="text-light text-center" style="padding-right:0px;">
          <b-row class="mr-auto">
            <b-col cols="auto" class="mr-auto p-2"></b-col>
            <b-col cols="auto" class="p-2">
              <b-button variant="ghost-secondary" v-on:click="overwritemodal = false">Cancel</b-button>
            </b-col>
            <b-col cols="auto" class="p-2">
              <b-button variant="success" @click="send_overwrites">Save</b-button>
            </b-col>
          </b-row>
        </b-container>
      </div>
    </b-modal>
    <b-modal
      title="Confirm Changes"
      size="sm"
      v-model="confirm_modal"
      centered
      class="card-accent-danger"
      :no-enforce-focus="true"
    >
      This will remove all overwrites and re-enable any disabled commands.
      <div slot="modal-footer" class="w-100">
        <b-container fluid class="text-light text-center" style="padding-right:0px;">
          <b-row class="mr-auto">
            <b-col cols="auto" class="mr-auto p-2"></b-col>
            <b-col cols="auto" class="p-2">
              <b-button variant="ghost-secondary" v-on:click="confirm_modal = false">Cancel</b-button>
            </b-col>
            <b-col cols="auto" class="p-2">
              <b-button variant="danger" @click="clear_all">Reset</b-button>
            </b-col>
          </b-row>
        </b-container>
      </div>
    </b-modal>
  </div>
</template>

<script>
import Vue from "vue";
import { Switch as cSwitch } from "@coreui/vue";
import { ClientTable, Event } from "vue-tables-2";
import VueNotifications from "vue-notifications";
import miniToastr from "mini-toastr";
import Multiselect from "vue-multiselect";

const toastTypes = {
  success: "success",
  error: "error",
  info: "info",
  warn: "warn"
};
miniToastr.init({ types: toastTypes });

function toast({ title, message, type, timeout, cb }) {
  return miniToastr[type](message, title, timeout, cb);
}

const toast_options = {
  success: toast,
  error: toast,
  info: toast,
  warn: toast
};
Vue.use(VueNotifications, toast_options);
Vue.use(ClientTable);

export default {
  name: "Commands",
  components: {
    ClientTable,
    Event,
    cSwitch,
    Multiselect
  },
  notifications: {
    applied_settings: {
      type: VueNotifications.types.success,
      title: "Success!",
      message: "Overwrites applied"
    },
    elevated_perms_notification: {
      type: VueNotifications.types.warn,
      title: "Caution!",
      timeout:60000,
      message: "Turning on this function is risky. If someone with bad intentions gains higher permissions, your server could be at risk. Carl-bot isn't liable for any harm caused by unauthorized access to these permissions."
    }
  },
  data: function() {
    return {
      command_data: [{}],
      selected_commands: [],
      labelIcon: {
        dataOn: "\u2713",
        dataOff: "\u2715"
      },
      selecting: false,
      overwritemodal: false,
      current_command: {},
      enabled_subcommands: [],
      tabIndex: 0,
      roles: [],
      channels: [],
      role_wl: [],
      role_bl: [],
      channel_wl: [],
      channel_bl: [],
      overrides: [],
      command_checks: false,
      name: "",
      redirection_channel: { name: "Do not redirect output", id: null },
      modonly: false,
      restricted: false,
      muted: false,
      sneaky: false,
      editing_category: false,
      global_role_wl: [],
      global_role_bl: [],
      global_channel_wl: [],
      global_channel_bl: [],
      global_modonly: false,
      elevated_permissions:false,
      global_modrole: { name: "No bot manager selected", id: null },
      global_bot_channels: [],
      confirm_modal: false,
      command_filter: "",
      match_desc: false,
      loading: true
    };
  },
  methods: {
    openmodal(data) {
      this.overwritemodal = true;
      this.reset_modal();
      this.current_command = data;
      this.role_wl = this.roles.filter(x =>
        (data.role_wl || []).includes(x.id)
      );
      this.role_bl = this.roles.filter(x =>
        (data.role_bl || []).includes(x.id)
      );
      this.channel_wl = this.channels.filter(x =>
        (data.channel_wl || []).includes(x.id)
      );
      this.channel_bl = this.channels.filter(x =>
        (data.channel_bl || []).includes(x.id)
      );
      this.overrides = this.roles.filter(x =>
        (data.overrides || []).includes(x.id)
      );
      if (data.redirection) {
        this.redirection_channel =
          data.redirection == 1
            ? { name: "DM author", id: 1 }
            : this.channels.find(x => x.id == data.redirection);
      }
      this.modonly = data.modonly || false;
      this.restricted = data.restricted || false;
      this.muted = data.muted || false;
      this.sneaky = data.delete || false;
      this.command_checks = data.checks;
    },
    openmultiplemodal() {
      this.overwritemodal = true;
      this.reset_modal();
      this.editing_category = false;
      this.current_command = this.selected_commands;
    },
    openmodalcategorically() {
      this.overwritemodal = true;
      this.reset_modal();
      this.editing_category = true;
      this.current_command = Object.keys(this.relevant_commands);
    },
    reset_globals() {
      this.global_role_wl = [];
      this.global_role_bl = [];
      this.global_channel_wl = [];
      this.global_channel_bl = [];
      this.global_modonly = false;
      this.elevated_permissions = false;
      this.global_modrole = { name: "No bot manager selected", id: null };
      this.global_bot_channels = [];
    },
    openconfirm() {
      this.confirm_modal = true;
      this.reset_modal();
      this.editing_category = true;
      this.current_command = Object.keys(this.relevant_commands);
    },
    apply_changes(cmds, enable) {
      if (!Array.isArray(cmds)) {
        cmds = [cmds];
      }
      this.command_data.forEach((obj, i) => {
        cmds.forEach(c => {
          if (c in obj.commands) {
            Object.assign(obj.commands[c], {
              role_wl: this.role_wl.map(x => x.id),
              role_bl: this.role_bl.map(x => x.id),
              channel_bl: this.channel_bl.map(x => x.id),
              channel_wl: this.channel_wl.map(x => x.id),
              overrides: this.overrides.map(x => x.id),
              redirection: this.redirection_channel
                ? this.redirection_channel.id
                : null,
              delete: this.sneaky,
              modonly: this.modonly,
              restricted: this.restricted,
              muted: this.muted
            });
            if (enable) {
              Object.assign(obj.commands[c], {
                enabled: true
              });
            }
          }
        });
      });
    },
    LightenDarkenColor(col, amt) {
      return (
        ((col & 0x0000ff) + amt) |
        ((((col >> 8) & 0x00ff) + amt) << 8) |
        (((col >> 16) + amt) << 16)
      ).toString(16);
    },
    reset_modal() {
      this.role_wl = [];
      this.role_bl = [];
      this.channel_wl = [];
      this.channel_bl = [];
      this.overrides = [];
      this.modonly = false;
      this.restricted = false;
      this.muted = false;
      this.sneaky = false;
      this.redirection_channel = { name: "Do not redirect output", id: null };
    },
    parseColor(r) {
      return r.color != null ? "#" + r.color.toString(16) : "#fefefe";
    },
    enable_all() {
      for (var command in this.relevant_commands) {
        this.relevant_commands[command].enabled = true;
      }
      this.toggle_many(Object.keys(this.relevant_commands), true);
    },
    disable_all() {
      for (var command in this.relevant_commands) {
        this.relevant_commands[command].enabled = false;
      }
      this.toggle_many(Object.keys(this.relevant_commands), false);
    },
    select_all() {
      for (var command in this.relevant_commands) {
        if (!this.selected_commands.includes(command)) {
          this.selected_commands.push(command);
        }
      }
    },
    elevated_perms_toggle(){
    if (!this.elevated_permissions){
     return this.elevated_perms_notification();
    }
    },
    clear_all() {
      this.confirm_modal = false;
      this.apply_changes(this.commands_to_be_sent, true);
      return this.patch_settings({
        reset: true,
        commands: this.commands_to_be_sent
      }).then(this.applied_settings({ message: "Category reset" }));
    },
    toggle(command, state) {
      return this.patch_settings({
        state: state,
        commands: [command]
      }).then(
        this.applied_settings({
          message: (state ? "Enabled " : "Disabled ") + command
        })
      );
    },
    intToHex(int) {
      if (!int) {
        return "eee";
      }
      return int.toString(16);
    },
    toggle_many(commands, state) {
      return this.patch_settings({
        state: state,
        commands: commands
      }).then(
        this.applied_settings({
          message: (state ? "Enabled " : "Disabled ") + "category"
        })
      );
    },
    send_globals() {
      return this.patch_settings({
        global: true,
        role_wl: this.global_role_wl.map(x => x.id),
        role_bl: this.global_role_bl.map(x => x.id),
        channel_wl: this.global_channel_wl.map(x => x.id),
        channel_bl: this.global_channel_bl.map(x => x.id),
        modonly: this.global_modonly,
        elevated_perms:this.elevated_permissions,
        modrole: this.global_modrole ? this.global_modrole.id : null,
        bot_channels: this.global_bot_channels.map(x => x.id)
      }).then(this.applied_settings({ message: "Global settings updated" }));
    },
    card_accent(cmd) {
      if (!cmd.enabled) {
        return "card-accent-danger";
      }
      try {
        if (
          cmd.role_wl.length ||
          cmd.role_bl.length ||
          cmd.channel_wl.length ||
          cmd.channel_bl.length ||
          !!cmd.redirection ||
          !!cmd.modonly ||
          !!cmd.restricted ||
          !!cmd.muted ||
          !!cmd.delete ||
          cmd.overrides.length
        ) {
          return "card-accent-info";
        }
      } catch {
        return "card-accent-success";
      }

      return "card-accent-success";
    },
    unselect_all() {
      this.selected_commands = this.selected_commands.filter(
        x => !(x in this.relevant_commands)
      );
    },
    send_overwrites() {
      this.apply_changes(this.commands_to_be_sent, false);
      return this.patch_settings({
        role_wl: this.role_wl.map(x => x.id),
        role_bl: this.role_bl.map(x => x.id),
        channel_wl: this.channel_wl.map(x => x.id),
        channel_bl: this.channel_bl.map(x => x.id),
        overrides: this.overrides.map(x => x.id),
        modonly: this.modonly,
        restricted: this.restricted,
        muted: this.muted,
        sneaky: this.sneaky,
        redirect: this.redirection_channel ? this.redirection_channel.id : null,
        commands: this.commands_to_be_sent,
        disabled_subcommands: Array.isArray(this.current_command)
          ? null
          : this.current_command.subcommands
              .filter(x => !x.enabled)
              .map(x => x.command)
      }).then(this.applied_settings({ message: "Overwrites applied" }));
    },
    patch_settings(obj) {
      this.overwritemodal = false;
      this.selecting = false;
      this.selected_commands = [];
      return this.axios.patch(
        "/api/v1/servers/" + this.$route.params.guild_id + "/commands",
        obj
      );
    }
  },
  beforeRouteUpdate(to, from, next) {
    next();
    this.axios
      .get("/api/v1/servers/" + this.$route.params.guild_id + "/commands")
      .then(r => {
        this.command_data = r.data.commands;
        this.channels = r.data.channels;
        this.roles = r.data.roles;
        this.tabIndex = 0;
        this.global_role_wl = this.roles.filter(x =>
          r.data.config.role_wl.includes(x.id)
        );
        this.global_role_bl = this.roles.filter(x =>
          r.data.config.role_bl.includes(x.id)
        );
        this.global_channel_wl = this.channels.filter(x =>
          r.data.config.channel_wl.includes(x.id)
        );
        this.global_channel_bl = this.channels.filter(x =>
          r.data.config.channel_bl.includes(x.id)
        );
        this.global_modonly = r.data.config.modonly;
        this.elevated_permissions = r.data.config.elevated_perms;
        this.global_modrole = this.roles.find(
          x => r.data.config.mod_role == x.id
        ) || { name: "No bot manager selected", id: null };
        if (r.data.config.bot_channels.length != 0) {
          this.global_bot_channels =
            r.data.config.bot_channels.map(x =>
              this.channels.find(y => y.id == x)
            ) || [];
        } else {
          this.global_bot_channels = [];
        }
      });
  },
  created() {
    this.$Progress.start();
    this.axios
      .get("/api/v1/servers/" + this.$route.params.guild_id + "/commands")
      .then(r => {
        this.command_data = r.data.commands;
        this.channels = r.data.channels;
        this.roles = r.data.roles;
        this.tabIndex = 0;
        this.global_role_wl = this.roles.filter(x =>
          r.data.config.role_wl.includes(x.id)
        );
        this.global_role_bl = this.roles.filter(x =>
          r.data.config.role_bl.includes(x.id)
        );
        this.global_channel_wl = this.channels.filter(x =>
          r.data.config.channel_wl.includes(x.id)
        );
        this.global_channel_bl = this.channels.filter(x =>
          r.data.config.channel_bl.includes(x.id)
        );
        this.global_modonly = r.data.config.modonly;
        this.elevated_permissions = r.data.config.elevated_perms;
        this.global_modrole = this.roles.find(
          x => r.data.config.mod_role == x.id
        ) || { name: "No bot manager selected", id: null };
        this.global_bot_channels = r.data.config.bot_channels
          .map(x => this.channels.find(y => y.id == x))
          .filter(z => !!z);
        this.$Progress.finish();
        this.loading = false;
      });
  },
  computed: {
    groupedChannels() {
      let groups = [
        {
          category: {
            id: null,
            name: "Uncategorized"
          },
          channels: []
        }
      ];
      let currentGroup = groups[0];
      for (let chn of this.channels.sort((a, b) => a.position - b.position)) {
        if (chn.type === 4) {
          currentGroup = { category: chn, channels: [] };
          groups.push(currentGroup);
        }
      }
      for (let chn of this.channels.sort((a, b) => a.position - b.position)) {
        if (chn.type === 4) {
          continue;
        }
        currentGroup = groups.find(g => {
          return g.category.id == chn.parent_id;
        });
        if (!currentGroup) {
          continue;
        }
        currentGroup.channels.push(chn);
      }
      return groups;
    },
    relevant_commands() {
      if (this.command_filter != "") {
        var cmds = {};
        this.command_data.map(x =>
          Object.entries(x.commands).map(elm => {
            const [k, v] = elm;
            if (Object.keys(cmds).length == 12) {
              return cmds;
            }
            if (
              k.includes(this.command_filter) ||
              (this.match_desc &&
                v.description &&
                v.description.toLowerCase().includes(this.command_filter))
            ) {
              cmds[k] = v;
            }
          })
        );
        return cmds;
      }
      return this.command_data[this.tabIndex - 1].commands;
    },
    redirectOptions() {
      return [
        { name: "Do not redirect output", id: null, parent_id: null },
        { name: "DM author", id: 1, parent_id: null },
        ...this.channels
      ];
    },
    groupedRedirectOptions() {
      let groups = [
        {
          category: {
            id: null,
            name: "Uncategorized"
          },
          channels: []
        }
      ];
      let currentGroup = groups[0];
      for (let chn of this.redirectOptions.sort(
        (a, b) => a.position - b.position
      )) {
        if (chn.type === 4) {
          currentGroup = { category: chn, channels: [] };
          groups.push(currentGroup);
          continue;
        }
        currentGroup = groups.find(g => g.category.id === chn.parent_id);
        if (!currentGroup) {
          continue;
        }
        currentGroup.channels.push(chn);
      }
      return groups;
    },
    botManagerRoles() {
      return [{ name: "No bot manager selected", id: null }, ...this.roles];
    },
    commands_to_be_sent() {
      if (!this.current_command) {
        return "";
      }
      if (!Array.isArray(this.current_command)) {
        return [this.current_command.name];
      }
      return this.editing_category
        ? Object.keys(
            this.command_data[Math.max(0, this.tabIndex - 1)].commands
          )
        : this.current_command;
    },
    modaltitle() {
      if (!this.current_command) {
        return "";
      }
      if (!Array.isArray(this.current_command)) {
        return "Overwrites for " + this.current_command.name;
      }
      return this.editing_category
        ? "Overwrites for " +
            this.command_data[Math.max(0, this.tabIndex - 1)].module
        : "Overwrites for " +
            this.current_command.length +
            " selected commands";
    }
  }
};
</script>

